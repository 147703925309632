import _taggedTemplateLiteral from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

import gql from "graphql-tag";
export var ADD_CART = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation($input: [SellerCartItemInput]) {\n    addCartSeller(input: $input) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var CHECKOUT_SELLER = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation($input: ChecoutOderInput!) {\n    checkout_seller(input: $input) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var CALCULATE_FORWARDER = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query($input: ChecoutOderInput!) {\n    calculate_forwarder(input: $input) {\n      isSuccess\n      message\n      data\n      shipping_price {\n        seller_name\n        sellerId\n        shipping_price\n      }\n    }\n  }\n"])));
export var DELETE_CART = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation($sellerId: Int, $_id: ID) {\n    deleteCartSeller(sellerId: $sellerId, _id: $_id) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var UPDATE_CART = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation($sellerId: Int, $_id: ID, $amount: Int) {\n    updateCartSeller(sellerId: $sellerId, _id: $_id, amount: $amount) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var UPDATE_GARANTEE_CART = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  mutation($seller_id: Int, $guarantee: Boolean) {\n    updateCartGaranteeSeller(\n      sellerId: $sellerId\n      _id: $_id\n      guarantee: $guarantee\n    ) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var GET_CARTS = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  query {\n    cart_list {\n      isSuccess\n      message\n      records {\n        _id\n        vendor\n        sellerId\n        qc\n        guarantee\n        items {\n          _id\n          productName\n          productId\n          mainImage\n          vendor\n          amount\n          price\n          skuId\n          url\n          remark\n          size {\n            weight\n          }\n          properties {\n            id\n            name\n            title\n            image\n          }\n        }\n      }\n    }\n  }\n"])));