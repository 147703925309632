import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import bigDecimal from "js-big-decimal";
export default {
  props: ["defaultValue", "step", "decimal", "disabledAppend", "min"],
  data: function data() {
    var _this$defaultValue;

    return {
      foo: (_this$defaultValue = this.defaultValue) !== null && _this$defaultValue !== void 0 ? _this$defaultValue : 0
    };
  },
  methods: {
    increment: function increment() {
      var val = bigDecimal.add(this.foo, Number(this.step) || 1);
      this.foo = Number(bigDecimal.round(val, Number(this.decimal) || 0));
    },
    decrement: function decrement() {
      var min = Number(this.min);

      if (this.foo - (Number(this.step) || 1) < min) {
        return;
      }

      var val = Number(bigDecimal.subtract(this.foo, Number(this.step) || 1));
      this.foo = Number(bigDecimal.round(val, Number(this.decimal) || 0));
    }
  },
  watch: {
    foo: {
      handler: function handler() {
        this.$emit("newdata", this.foo);
        this.$emit("onChange", this.foo);
      },
      deep: true
    }
  }
};